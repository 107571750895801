import React from 'react'
import Layout from '../templates/layout'
import Hero from '../components/hero'
import Strapline from '../components/strapline'
import Content from '../components/content'
import PageHelmet from '../components/page-helmet'
import Img from 'gatsby-image'
import {useIsScrolled} from '../helpers/hooks'
import {graphql} from 'gatsby'
import {unblockPortableText} from '../helpers/sanity'
import {localiseObject} from '../helpers/locale'
import {SITE_URL} from '../helpers/constants'
import '../scss/pages/index.scss'

export const query = graphql`

  query ExperientialPageQuery {
    sanityExperientialPage {
      id
      pageSeo {
        title {
          en
          fr
        }
        description {
          en
          fr
        }
      }
      _rawHero
      hero {
        poster {
          asset {
            fluid(maxWidth: 1500) {
              ...GatsbySanityImageFluid
            }
            fixed(width: 1200) {
              src
            }
          }
        }
      }
      _rawIntro
      _rawIconShowcase
      _rawPortraitVideos
      _rawProcess
      _rawCasestudyList(resolveReferences: {maxDepth: 4})
      portraitVideos {
        _type
        caption {
          _key
          _type
          en
          fr
        }
        body {
          _key
          en {
            _key
            _type
            style
            list
          }
          fr {
            _key
            _type
            style
            list
          }
        }
        video {
          asset {
            _key
            _type
            status
            assetId
            playbackId
            filename
            thumbTime
          }
        }
      }
    }
  }
`

export default function Experiential ({data}) {

  const isScrolled = useIsScrolled()

  const {sanityExperientialPage: {pageSeo, _rawHero, hero, _rawIntro, _rawIconShowcase, portraitVideos, _rawPortraitVideos, _rawProcess, _rawCasestudyList}} = data

  // annoyingly mux videos only like portraitVideos and portable text only like _raw
  const portraitVideosCombined = {
    ...portraitVideos,
    body: _rawPortraitVideos.body
  }

  return (
    <Layout>
      <PageHelmet
        url={`${SITE_URL}/experiential`}
        pageSeo={pageSeo}
        imageUrl={hero?.poster?.asset?.fixed?.src}
      />
      {hero &&
        <Hero fill={isScrolled} straplineLeft={true}>
          <Img fluid={hero?.poster?.asset?.fluid} />
          <Strapline
            heading={localiseObject(_rawHero.title)}
            text={unblockPortableText(localiseObject(_rawHero.body))}
          />
        </Hero>
      }
      {_rawIntro && <Content content={_rawIntro} />}
      {_rawIconShowcase && <Content content={_rawIconShowcase} />}
      {portraitVideos && <Content content={portraitVideosCombined} />}
      {_rawProcess && <Content content={_rawProcess} />}
      {_rawCasestudyList && <Content content={_rawCasestudyList} />}
    </Layout>
  )
}
